
import { Vue } from "vue-class-component";
import { BaggingGroupingData } from "@/domain/entities/BaggingGrouping";
import debounce from "lodash/debounce";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
import { BAGGING_GROUPING } from "@/app/infrastructures/misc/RolePermission";
import { BaggingGroupingController } from "@/app/ui/controllers/BaggingGroupingController";

export default class BaggingGroupingList extends Vue {
  mounted() {
    BaggingGroupingController.initBaggingGroupingList();
  }

  fetchBagginGroupingList() {
    BaggingGroupingController.fetchBaggingGroupingList();
  }

  get getCurrentRoute() {
    return this.$route.meta.title;
  }

  get isAbleDetail() {
    return checkRolePermission(BAGGING_GROUPING.DETAIL);
  }

  //   filter
  get filterData() {
    return BaggingGroupingController.filter;
  }

  // destination
  get loadingDestination() {
    return LocationController.isLoading;
  }
  get destinationList() {
    return LocationController.cityData.cityData;
  }
  onSelectDestination(value: any) {
    this.filterData.destinationId = value || "";
    BaggingGroupingController.setFirstPage();
    this.fetchBagginGroupingList();
  }

  // region
  get loadingRegion() {
    return LocationController.isLoading;
  }
  getCityList = debounce((value: string) => {
    if (!value.length || value.length >= 3) {
      LocationController.getCityList({
        page: 1,
        limit: 10,
        search: value,
        status: ""
      });
    }
  }, 500);
  get regionList() {
    return LocationController.cityData.cityData;
  }
  onSelectRegion(value: any) {
    this.filterData.regionId = value || "";
    BaggingGroupingController.setFirstPage();
    this.fetchBagginGroupingList();
  }

  // status
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";
  statusValue = "";
  onOpenStatus(value: boolean) {
    this.statusSelect = value;
  }
  onSelectStatus(name: string, value: string) {
    this.statusName = name;
    this.filterData.status = value;
    this.onOpenStatus(false);
    BaggingGroupingController.setFirstPage();
    this.fetchBagginGroupingList();
  }

  get isEmpty() {
    return !this.listData.length && this.firstRequest && !this.isLoading;
  }

  onRequest() {
    this.fetchBagginGroupingList();
  }
  get isLoading() {
    return BaggingGroupingController.isLoading;
  }
  get pagination() {
    return BaggingGroupingController.baggingGroupingList.pagination;
  }
  get listData() {
    return BaggingGroupingController.baggingGroupingList.data;
  }
  get isError() {
    return BaggingGroupingController.isError;
  }
  get errorCause() {
    return BaggingGroupingController.errorCause;
  }
  get firstRequest() {
    return BaggingGroupingController.firstRequest;
  }

  columns = [
    {
      name: "ID",
      styleHead: "w-2/20 text-left",
      render: (item: BaggingGroupingData) => {
        return `<div class="text-black-lp-300">${item.id}</div>`;
      }
    },
    {
      name: "ID Destinasi",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: BaggingGroupingData) => {
        return `<div class='text-black-lp-300 mr-2 flex flex-row'>
                    <div class='rounded px-2 py-0 bg-gray-lp-200'>${item.destinationId}</div>
                </div>`;
      }
    },
    {
      name: "Nama Destinasi",
      styleHead: "w-4/20 text-left",
      render: (item: BaggingGroupingData) => {
        return `<div class="text-black-lp-300">${item.destinationName}</div>`;
      }
    },
    {
      name: "ID Region",
      styleHead: "w-3/20 text-left",
      render: (item: BaggingGroupingData) => {
        return `<div class='text-black-lp-300 mr-2 flex flex-row'>
                    <div class='rounded px-2 py-0 bg-gray-lp-200'>${item.regionId}</div>
                </div>`;
      }
    },
    {
      name: "Nama Region",
      styleHead: "w-4/20 text-left",
      render: (item: BaggingGroupingData) => {
        return `<div class="text-black-lp-300">${item.regionName}</div>`;
      }
    },
    {
      name: "Status",
      styleHead: "w-4/20 text-left",
      render: (item: any) => {
        return `<div class="flex">
            <div class="rounded-full px-2 py-0 ${
              item.status ? "bg-green-lp-300" : "bg-red-300"
            } ${item.status ? "text-green-lp-200" : "text-red-lp-200"}">
        <span class="capitalize">${
          item.status ? "Active" : "Inactive"
        }</span></div></div>`;
      }
    }
  ];
}
