import { RequestBaggingGroupingList } from "@/data/payload/api/BaggingGroupingApiRequest";
import { BaggingGroupingListEntities } from "@/domain/entities/BaggingGrouping";
import { Pagination } from "@/domain/entities/Pagination";
import store from "@/store";
import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import { BaggingGroupingPresenter } from "../presenters/BaggingGroupingPresenter";

export interface BaggingGroupingState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  firstRequest: boolean;
}
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "bagging_grouping"
})
class BaggingGroupingStore extends VuexModule implements BaggingGroupingState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public firstRequest = false;
  public baggingGroupingList = new BaggingGroupingListEntities({
    pagination: new Pagination(1, 20),
    data: []
  });
  public filter = {
    destinationId: "" as any,
    regionId: "" as any,
    status: ""
  };

  @Action
  public getBaggingGroupingList(params: RequestBaggingGroupingList) {
    this.setLoading(true);
    const presenter = container.resolve(BaggingGroupingPresenter);
    presenter
      .getBaggingGroupingList(params)
      .then((res: BaggingGroupingListEntities) => {
        this.setBaggingGroupingList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async fetchBaggingGroupingList() {
    await this.setFirstRequest(false);
    this.getBaggingGroupingList(
      new RequestBaggingGroupingList({
        isTotalData: true,
        page: this.baggingGroupingList.pagination.page,
        limit: this.baggingGroupingList.pagination.limit,
        destinationId: this.filter.destinationId?.code || "",
        regionId: this.filter.regionId?.code || "",
        status: this.filter.status
      })
    );
  }

  @Action
  public async initBaggingGroupingList() {
    await this.setFirstPage();
    await this.setFirstRequest(true);
    await this.resetFilter();
    this.getBaggingGroupingList(
      new RequestBaggingGroupingList({
        isTotalData: true,
        page: this.baggingGroupingList.pagination.page,
        limit: this.baggingGroupingList.pagination.limit,
        destinationId: this.filter.destinationId,
        regionId: this.filter.regionId,
        status: this.filter.status
      })
    );
  }

  @Action
  public async resetFilter() {
    this.setFilter({
      destinationId: "",
      regionId: "",
      status: ""
    });
  }

  @Action
  public async setFirstPage() {
    this.baggingGroupingList.pagination.page = 1;
  }

  @Mutation
  public async setBaggingGroupingList(data: BaggingGroupingListEntities) {
    this.baggingGroupingList = data;
  }

  @Mutation
  public async setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Mutation
  public setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  public setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  public setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  public setFilter(value: any) {
    this.filter = value;
  }
}
export const BaggingGroupingController = getModule(BaggingGroupingStore);
