import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }
const _hoisted_2 = { class: "flex w-3/4 gap-x-4" }
const _hoisted_3 = { class: "font-semibold text-20px mr-8 whitespace-no-wrap text-black-lp-200 capitalize whitespace-nowrap" }
const _hoisted_4 = { class: "w-1/3" }
const _hoisted_5 = { class: "w-1/3" }
const _hoisted_6 = {
  key: 1,
  class: "w-full h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectSearch = _resolveComponent("SelectSearch")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString(_ctx.getCurrentRoute), 1),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_SelectSearch, {
              onFocus: _ctx.getCityList,
              onFilter: _ctx.getCityList,
              isLoading: _ctx.loadingDestination,
              options: _ctx.destinationList,
              keyName: "name",
              keyValue: "id",
              placeholder: "Ketik atau pilih nama destinasi",
              modelValue: _ctx.filterData.destinationId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterData.destinationId = $event)),
              onChange: _ctx.onSelectDestination
            }, null, 8, ["onFocus", "onFilter", "isLoading", "options", "modelValue", "onChange"])
          ]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_SelectSearch, {
              onFocus: _ctx.getCityList,
              onFilter: _ctx.getCityList,
              isLoading: _ctx.loadingRegion,
              options: _ctx.regionList,
              keyName: "name",
              keyValue: "id",
              placeholder: "Ketik atau pilih nama region",
              modelValue: _ctx.filterData.regionId,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filterData.regionId = $event)),
              onChange: _ctx.onSelectRegion
            }, null, 8, ["onFocus", "onFilter", "isLoading", "options", "modelValue", "onChange"])
          ]),
          _createVNode(_component_Select, {
            dataSelect: _ctx.statusData,
            onOpenSelect: () => _ctx.onOpenStatus(true),
            onCloseSelect: () => _ctx.onOpenStatus(false),
            onChooseSelect: _ctx.onSelectStatus,
            value: _ctx.statusName,
            isOpenMenu: _ctx.statusSelect,
            class: "w-1/5"
          }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"])
        ])
      ]),
      _: 1
    }),
    (_ctx.isError && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          errorType: _ctx.errorCause,
          onTryAgain: _ctx.onRequest
        }, null, 8, ["errorType", "onTryAgain"]))
      : (_openBlock(), _createBlock("div", _hoisted_6, [
          _createVNode(_component_TableV2, {
            class: "my-2",
            isEmpty: _ctx.isEmpty,
            columns: _ctx.columns,
            data: _ctx.listData,
            loading: _ctx.isLoading,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[3] || (_cache[3] = ($event: any) => (_ctx.pagination = $event)),
            onRequest: _ctx.onRequest,
            paginationStyle: "v3",
            isPinnedScrollBottom: "",
            isDetailAble: _ctx.isAbleDetail
          }, null, 8, ["isEmpty", "columns", "data", "loading", "pagination", "onRequest", "isDetailAble"])
        ]))
  ]))
}