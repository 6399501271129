import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class RequestBaggingGroupingList {
  page = 1;
  limit = 10;
  isTotalData = true;
  destinationId = "";
  regionId = "";
  status = "";

  constructor(fields?: Partial<RequestBaggingGroupingList>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
